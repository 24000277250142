import * as React from "react";
import ShowFolder from "./ShowFolder";
import { Route, Routes, NavLink, useNavigate, HashRouter, } from "react-router-dom";
import SubFolders from "./SubFolders";
export default function App() {
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Zon Dir"),
        React.createElement(HashRouter, null,
            React.createElement(MyRouted, null))));
}
function MyRouted() {
    const navigate = useNavigate();
    React.useEffect(() => navigate("/folder/0"), []);
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", null,
            React.createElement(NavLink, { to: "/folder/0" }, "Main folder")),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/folder/:id", element: React.createElement(ShowFolder, null) }),
            React.createElement(Route, { path: "/subfolders-of/:id", element: React.createElement(SubFolders, { "data-dir": "sub" }) }),
            React.createElement(Route, { path: "/superfolders-of/:id", element: React.createElement(SubFolders, { "data-dir": "super" }) }))));
}
