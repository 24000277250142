import * as React from "react";
import { AppData } from "../DataDispatcher";
import { useParams } from "react-router-dom";
import { take } from "../util/iterators";
// import { zon_backend } from "../../../declarations/zon_backend";
export default function ShowFolder() {
    const { id } = useParams();
    const data = new AppData(id);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            "Folder: ",
            React.createElement("span", { lang: data.locale() }, data.folderName())),
        data.folderDescription() ? React.createElement("p", { lang: data.locale() }, data.folderDescription()) : "",
        React.createElement("h3", null, "Sub-categories"),
        React.createElement("ul", null, take(data.subCategories(), 3).map(x => React.createElement("li", { lang: x.locale, key: x.id },
            x.type == 'public' ? React.createElement("span", { title: "Communal folder" }, "\uD83D\uDC65") : React.createElement("span", { title: "Owned folder" }, "\uD83D\uDC64"),
            React.createElement("a", { href: `#/folder/${x.id}` }, x.title)))),
        React.createElement("p", null,
            React.createElement("a", { href: `#/subfolders-of/${id}` }, "More..."),
            " ",
            React.createElement("a", { href: "#" }, "Create")),
        React.createElement("h3", null, "Super-categories"),
        React.createElement("ul", null, take(data.superCategories(), 3).map(x => React.createElement("li", { lang: x.locale, key: x.id },
            x.type == 'public' ? React.createElement("span", { title: "Communal folder" }, "\uD83D\uDC65") : React.createElement("span", { title: "Owned folder" }, "\uD83D\uDC64"),
            React.createElement("a", { href: `#/folder/${x.id}` }, x.title)))),
        React.createElement("p", null,
            React.createElement("a", { href: `#/superfolders-of/${id}` }, "More..."),
            " ",
            React.createElement("a", { href: "#" }, "Create")),
        data.items().map(item => React.createElement("div", { key: item.id },
            React.createElement("p", { lang: item.locale, key: item.id },
                item.price ? React.createElement(React.Fragment, null,
                    "(",
                    item.price,
                    " ICP) ") : "",
                item.link ? React.createElement("a", { href: item.link }, item.title) : item.title),
            React.createElement("p", { lang: item.locale, key: item.id + 'a', style: { marginLeft: '1em' } }, item.description))),
        React.createElement("p", null,
            React.createElement("a", { href: `#` }, "More..."),
            " ",
            React.createElement("a", { href: "#" }, "Create"))));
}
