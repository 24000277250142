import * as React from "react";
import { AppData } from "../DataDispatcher";
import { useParams } from "react-router-dom";
// import { zon_backend } from "../../../declarations/zon_backend";
export default function SubFolders(props) {
    const { id } = useParams();
    const data = new AppData(id);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            props['data-dir'] == 'super' ? "Super-folders" : "Subfolders",
            " of: ",
            data.folderName()),
        React.createElement("ul", null, (props['data-dir'] == 'super' ? data.superCategories() : data.subCategories()).map(x => React.createElement("li", { key: x.id },
            React.createElement("p", null,
                x.type == 'public' ? React.createElement("span", { title: "Communal folder" }, "\uD83D\uDC65") : React.createElement("span", { title: "Owned folder" }, "\uD83D\uDC64"),
                React.createElement("a", { lang: x.locale, href: `#/folder/${x.id}` }, x.title)),
            x.description ? React.createElement("p", { lang: x.locale },
                React.createElement("small", null, x.description)) : "")))));
}
